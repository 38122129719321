import { isPossiblePhoneNumber, isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js'
import { CURRENCY } from '$lib/consts'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

const langs = {
	WOLTAIR_CZ: 'cs',
	WOLTAIR_PL: 'pl',
	WOLTAIR_DE: 'de',
	WOLTAIR_IT: 'it'
}

const lang = langs[PUBLIC_WOLTAIR_WEB_CODE as keyof typeof langs]

export const formatNumber = (amount, round = 2) => {
	if (!amount && amount !== 0) return ''
	const numberFormatter = new Intl.NumberFormat(lang, {
		minimumFractionDigits: 0,
		maximumFractionDigits: round
	})
	return numberFormatter.format(amount)
}

export const formatCurrency = (currency) => {
	if (!currency) return ''

	const currencyFormatter = new Intl.NumberFormat(lang, {
		style: 'currency',
		currency
	})
	return currencyFormatter.formatToParts(0).find((x) => x.type === 'currency').value
}

export const formatAmount = (
	amount: number | unknown,
	{
		style = 'decimal',
		currency = null,
		unit = null,
		round = 2
	}: {
		style?: 'decimal' | 'percent' | 'currency' | 'unit'
		currency?: string | null
		unit?: string | null
		round?: number
	} = {}
) => {
	if (typeof amount !== 'number') return ''

	interface Options {
		style: 'decimal' | 'percent' | 'currency' | 'unit'
		currency?: string
		unit?: string
		minimumFractionDigits: number
		maximumFractionDigits: number
	}

	const options: Options = {
		style: style || 'decimal',
		minimumFractionDigits: currency === CURRENCY.PLN && round >= 2 ? 2 : 0,
		maximumFractionDigits: round
	}

	if (currency) {
		options.style = 'currency'
		options.currency = currency
	}

	if (unit) {
		options.style = 'unit'
		options.unit = unit
	}

	const numberFormatter = new Intl.NumberFormat(lang, options)
	return numberFormatter.format(amount)
}

export function validatePhoneNumber(phone, country = 'CZ') {
	if (!phone || !country) return false
	return isPossiblePhoneNumber(phone, country) && isValidPhoneNumber(phone, country)
}

export function formatPhoneNumber(phone, country = 'CZ') {
	if (!phone) return
	if (!validatePhoneNumber(phone, country)) return phone

	const phoneNumber = parsePhoneNumber(phone, country)
	return phoneNumber.formatInternational()
}
